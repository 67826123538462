import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/styles";
import { AppBar, Toolbar, Badge, Hidden, IconButton } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import NotificationsIcon from "@material-ui/icons/NotificationsOutlined";
import { logout } from "../../../../utils/Storage";
import Logo from "./../../../../assets/LOGO.svg";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { api_get } from "../../../../utils/Api";
import useAuth from "./../../../../hooks/useAuth";
const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: "none",
  },
  flexGrow: {
    flexGrow: 1,
  },
  signOutButton: {
    marginLeft: theme.spacing(1),
  },
}));

const Topbar = (props) => {
  const { className, onSidebarOpen, ...rest } = props;
  const dispatch = useDispatch();
  const theme = useTheme();
  const classes = useStyles();
  const { logout } = useAuth();
  const history = useHistory();
  const [notifications] = useState([]);
  const handleLogout = async () => {
    try {
      await logout();
      dispatch({ type: "LOGOUT" });
    } catch (err) {
      console.error(err);
    }
  };
  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
      style={{ backgroundColor: "#FEC423" }}
    >
      <Toolbar>
        <RouterLink
          to="/"
          style={{
            display: "flex",
            gap: "10px",
            textDecoration: "none!important",
          }}
        ></RouterLink>
        <Hidden>
          <IconButton
            style={{ marginLeft: "45px" }}
            color="inherit"
            onClick={onSidebarOpen}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
        <div className={classes.flexGrow} />
        <Hidden mdDown>
          <IconButton color="inherit">
            <Badge
              badgeContent={notifications.length}
              color="primary"
              variant="dot"
            >
              <NotificationsIcon />
            </Badge>
          </IconButton>
        </Hidden>
        <IconButton
          className={classes.signOutButton}
          color="inherit"
          onClick={handleLogout}
        >
          <ExitToAppIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func,
};

export default Topbar;
